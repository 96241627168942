<script>
import { mapState, mapActions, mapMutations } from "vuex";
import TariffItem from "@/components/tariff/TariffItem.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import formatTariff from "@/package/helpers/format-tariff.helpers";
// import BannerYandexSplit from "@/components/BannerYandexSplit.vue";

export default {
  name: "Tariffs",

  components: {
    // BannerYandexSplit,
    TariffItem,
    MainLoader,
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      tariffs: (state) => state.tariff.tariffs,
    }),

    formattedTariffName() {
      return formatTariff(this.user.tariff?.code);
    },
  },

  methods: {
    ...mapMutations({
      setYandexScriptStatus: "SET_YANDEX_SCRIPT_STATUS",
    }),

    ...mapActions({
      loadTariffs: "tariff/loadTariffs",
    }),
  },

  mounted() {
    this.loadTariffs().finally(() => {
      this.loading = false;
    });

    // let data = this;

    // function changeScriptStatus(status) {
    //   data.setYandexScriptStatus(status);
    // }

    // if (!document.getElementById("yandexPayScript")) {
    //   const yandexSplitScript = document.createElement("script");
    //
    //   yandexSplitScript.id = "yandexPayScript";
    //   yandexSplitScript.src = "https://pay.yandex.ru/sdk/v1/pay.js";
    //   yandexSplitScript.async = true;
    //   yandexSplitScript.onload = function () {
    //     changeScriptStatus.call(data, "LOADED");
    //   };
    //
    //   document.body.appendChild(yandexSplitScript);
    // }
  },
};
</script>

<template>
  <div class="tariffs default-content-padding">
    <div class="tariffs__info info">
      <div class="info__description">Ваш тариф</div>
      <div class="info__value">{{ formattedTariffName }}</div>
    </div>

    <!--    <BannerYandexSplit class="tariffs__banner-yandex-split" />-->

    <MainLoader v-if="loading" />

    <div v-else class="tariffs__list">
      <TariffItem v-for="tariff in tariffs" :key="tariff.id" :tariff="tariff" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tariffs {
  padding-top: $space-xxl;
  padding-bottom: $space-xxxl;
  background-color: $background-white;

  &__banner-yandex-split {
    margin-bottom: 40px;
  }

  .info {
    margin: 0 0 40px;

    &__description {
      color: $light-sixth;
      @include text-1;
      margin: 0 0 $space-xs;
    }

    &__value {
      color: $dark-primary;
      @include title-3;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 900px) {
  .tariffs {
    &__banner-yandex-split {
      margin-bottom: 32px;
    }

    .info {
      margin-bottom: 32px;
    }
  }
}
</style>
