<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { TARIFFS_DESCRIPTIONS } from "@/package/const/tariffs-descriptions";
import timer from "@/mixins/timer.mixins";
import formatTariff from "@/package/helpers/format-tariff.helpers";
// import onYaPayLoad from "@/package/usecases/yandex-pay-init";
import MainButton from "@/components/helpers/MainButton";
import MainNotice from "@/components/helpers/MainNotice.vue";

export default {
  components: { MainButton, MainNotice },

  mixins: [timer],

  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      balanceError: false,
      loading: false,

      show_installment: false,

      isShowNotice: false,
      noticeType: "",
      noticeTitle: "",

      TARIFFS_DESCRIPTIONS: TARIFFS_DESCRIPTIONS,
    };
  },

  watch: {
    // yandexScriptStatus: {
    //   immediate: true,
    //   handler() {
    //     if (
    //       this.yandexScriptStatus === "LOADED" &&
    //       this.tariff.user_price <= 150000 &&
    //       this.tariff.can_buy
    //     ) {
    //       onYaPayLoad({
    //         id: `#yandex-split-${this.tariff.code}`,
    //         amount: `${this.tariff.user_price}`,
    //       });
    //       onYaPayLoad({
    //         id: `#mobile-yandex-split-${this.tariff.code}`,
    //         amount: `${this.tariff.user_price}`,
    //       });
    //     }
    //   },
    // },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      tariffs: (state) => state.tariff.tariffs,
      innerWidth: (state) => state.innerWidth,
      yandexScriptStatus: (state) => state.yandexScriptStatus,
    }),

    ...mapGetters({
      getUserDistributorAccount: "auth/getUserDistributorAccount",
    }),

    formattedUserPrice() {
      return this.tariff.user_price !== 0
        ? this.tariff.user_price.toLocaleString("ru-RU")
        : this.formattedPrice;
    },

    formattedPrice() {
      return this.tariff.price.toLocaleString("ru-RU");
    },

    discount() {
      return this.tariff.until ? "40" : "27";
    },

    showVipGoldBuyingAlert() {
      const userTariffCode = this.user?.tariff?.code;

      return (
        userTariffCode !== "vip_d" &&
        userTariffCode !== "vip_gold_d" &&
        this.tariff.code === "vip_gold_d"
      );
    },

    showBuyButton() {
      return (
        this.tariff.id >= this.user?.tariff?.id || this.user.tariff === null
      );
    },

    buyButtonText() {
      return this.tariff.code === this.user?.tariff?.code
        ? "Ваш тариф"
        : "Купить";
    },

    // installmentsLength() {
    //   return this.tariffs.filter(
    //     (installment) =>
    //       installment.installment &&
    //       installment.installment.status !== "declined"
    //   ).length;
    // },

    installmentStatus() {
      if (this.tariff?.installment?.status) {
        switch (this.tariff.installment.status) {
          case "awaited":
            return {
              status: "awaited",
              title: "Ожидайте ответа",
              color: "awaited",
            };
          case "paid":
            return {
              status: "paid",
              title: "Заявка одобрена",
              color: "success",
            };
          case "declined":
            return {
              status: "declined",
              title: "Заявка отклонена",
              color: "warning",
            };
          default:
            return "";
        }
      }
      return "";
    },
  },

  methods: {
    ...mapActions({
      loadTariffs: "tariff/loadTariffs",
      buyTariffInstallment: "tariff/buyTariffInstallment",
      disableTariffInstallment: "tariff/disableTariffInstallment",
    }),

    redirectToTariff() {
      this.$router.push({
        name: "TariffBuy",
        params: { tariffId: this.tariff.id },
      });
    },

    formatTariffName(tariffCode) {
      return formatTariff(tariffCode);
    },

    disableInstallment(tariffId) {
      this.show_installment = false;
      this.disableTariffInstallment(tariffId)
        .then(() => {
          this.loadTariffs();
        })
        .catch(() => {
          this.show_installment = true;
        });
    },

    openFlexiPay() {
      window.open("https://lk.flexipay.ru/login", "_blank");
    },
  },

  mounted() {
    if (this.tariff.until) {
      this.startTimer(this.tariff.until);
    }
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<template>
  <div
    class="tariff"
    :class="{
      tariff_small: tariff.code === 'business_d' || tariff.code === 'premium_d',
    }"
  >
    <div
      class="tariff__description"
      :class="[
        tariff.code,
        { 'tariff_padding-bottom': showBuyButton },
        { 'tariff_padding-bottom_big': showBuyButton && tariff.can_buy },
        {
          tariff__description_installment:
            tariff.installment && show_installment,
        },
      ]"
    >
      <div v-if="tariff.until" class="tariff__discount discount">
        <div class="discount__amount">
          <p>-{{ discount }}%</p>
        </div>
        <div class="discount__time-left">
          <div v-html="timeLeft('D:H:M')"></div>
          <span>Успей купить</span>
        </div>
      </div>

      <div class="tariff__title title title_top-left">
        <div class="title__img">
          <img
            :src="
              require(`../../assets/icons/tariffs/${tariff.code.toLocaleLowerCase()}_dark.svg`)
            "
            :alt="tariff.name"
          />
        </div>

        <div class="title__wrapper">
          <div class="title__name">
            <h4>
              {{ formatTariffName(tariff.code) }}
            </h4>

            <p v-if="showVipGoldBuyingAlert" class="title__alert">
              Для покупки необходим тариф Франшиза
            </p>

            <div v-else class="title__price">
              <p>{{ formattedUserPrice }} RC</p>
              <span
                v-if="
                  tariff.user_price !== tariff.price && tariff.user_price !== 0
                "
              >
                {{ formattedPrice }} RC
              </span>
            </div>
          </div>

          <div v-if="showBuyButton" class="tariff__btn">
            <MainButton
              :title="buyButtonText"
              :color="'gold'"
              :disabled="!tariff.can_buy"
              @click="redirectToTariff"
            />

            <div
              :id="`yandex-split-${tariff.code}`"
              class="tariff__yandex-split"
            ></div>
          </div>
        </div>
      </div>

      <div
        class="tariff__info tariff-info tariff-info_top-right"
        v-html="TARIFFS_DESCRIPTIONS[tariff.code].mainDescription"
      ></div>

      <div
        v-if="TARIFFS_DESCRIPTIONS[tariff.code].additionalDescription"
        class="tariff__additional-info additional-info additional-info_bottom"
        v-html="TARIFFS_DESCRIPTIONS[tariff.code].additionalDescription"
      ></div>

      <div v-if="showBuyButton" class="tariff__btn-mobile">
        <MainButton
          :title="buyButtonText"
          :color="'gold'"
          :disabled="!tariff.can_buy"
          @click="redirectToTariff"
        />

        <div
          :id="`mobile-yandex-split-${tariff.code}`"
          class="tariff__yandex-split"
        ></div>
      </div>
    </div>

    <div v-if="installmentStatus && show_installment" class="installment">
      <div
        v-if="installmentStatus.status === 'declined'"
        class="installment-close"
      >
        <button @click="disableInstallment(tariff.id)" />
      </div>
      <div class="installment-info">
        <div class="installment-description">
          <p v-if="installmentStatus.status === 'paid' && !tariff.can_buy">
            Вы купили тариф {{ formatTariffName(tariff.code) }} в рассрочку
            через сервис оплаты
          </p>
          <p v-else>
            Вы отправили заявку на покупку тарифа
            {{ formatTariffName(tariff.code) }} в рассрочку через сервис оплаты
          </p>
          <span
            v-if="installmentStatus.status && tariff.can_buy"
            :class="installmentStatus.color"
          >
            {{ installmentStatus.title }}
          </span>
        </div>
        <div
          v-if="installmentStatus.status !== 'awaited'"
          class="installment-actions"
        >
          <p v-if="installmentStatus.status === 'declined'">
            Попробуйте оформить заявку ещё раз, в случае повторного отказа
            <a href="https://https://support.rc.today/sign-in?source=cd">
              обратитесь в тех. поддержку
            </a>
          </p>
          <p v-if="installmentStatus.status === 'paid' && tariff.can_buy">
            Мы проверим данные и поменяем вам тариф в течение 3 дней
          </p>
          <p v-if="installmentStatus.status === 'paid' && !tariff.can_buy">
            <MainButton
              title="Перейти в сервис"
              color="dark"
              padding="12px 32px"
              @click="openFlexiPay"
            />
          </p>
        </div>
      </div>
    </div>

    <MainNotice
      v-if="isShowNotice"
      :title="noticeTitle"
      :notice-type="noticeType"
      @close="isShowNotice = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.tariff {
  margin: 0 0 $space-l;
  max-width: 918px;

  &:has(.tariff__discount) {
    margin-top: 32px;
  }

  &_small {
    width: 100%;
    max-width: 600px;
  }

  @media (max-width: 1440px) {
    max-width: 530px;
  }

  ::v-deep .tariff__yandex-split .ya-pay-button {
    height: 48px !important;
    margin-top: 12px !important;
  }

  &__description {
    padding: $space-inset-l;
    background-color: $light-second;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 360px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &_installment {
      border-radius: 8px 8px 0 0;
    }

    @media (max-width: 1440px) {
      display: grid;
      max-width: 530px;
      width: 100%;
      grid-template-columns: 1fr 224px;
      gap: 0 32px;
      grid-template-areas:
        "top-left top-right"
        "bottom bottom";

      .title {
        margin-right: 0;
        width: 100%;

        &_top-left {
          grid-area: top-left;
        }
      }

      .tariff-info {
        width: 100%;

        &_top-right {
          grid-area: top-right;
        }
      }

      .additional-info {
        margin-left: 0;
        margin-top: $space-xl;
        width: 100%;

        &_bottom {
          grid-area: bottom;
        }
      }
    }

    @media (max-width: 576px) {
      display: flex;
      flex-direction: column;

      &_padding-bottom {
        padding-bottom: 112px;

        &_big {
          padding-bottom: 172px;
        }
      }

      .title {
        margin-bottom: $space-l;
        flex-direction: row;
        align-items: center;

        &__img {
          margin-bottom: 0;
          margin-right: $space-m;
        }
      }

      .discount {
        width: calc(100% - 16px);
        left: 8px;
        top: -40px;
      }
    }

    @media (max-width: 425px) {
      padding: $space-l;

      &_padding-bottom {
        padding-bottom: 104px;

        &_big {
          padding-bottom: 164px;
        }
      }
    }
  }

  &__discount {
    position: absolute;
    left: 16px;
    top: -4px;
    border: 3px solid $gold-main;
    border-radius: 8px;
    background-color: $light-primary;
    display: flex;
    align-items: center;
    padding: 9px 13px;
  }

  .discount {
    &__amount {
      margin-right: 24px;

      p {
        @include text-1;
        color: $dark-primary;
      }
    }

    &__time-left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        @include caption-1;
        color: $dark-fifth;
      }

      @media (max-width: 576px) {
        flex-direction: row;
        align-items: center;

        span {
          margin-left: 8px;
        }
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 200px;
    margin-right: $space-xl;
  }

  .title {
    &__wrapper {
      width: 100%;
    }

    &__img {
      display: flex;
      margin-bottom: 4px;
    }

    &__name {
      h4 {
        @include title-2;
        color: $dark-primary;
        margin-bottom: 4px;
      }

      .title__alert {
        @include body-1;
        color: $dark-third;
      }
    }

    &__price {
      p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include text-1;
        color: $dark-primary;
        white-space: nowrap;
      }

      span {
        @include text-2;
        color: $dark-third;
        text-decoration: line-through;
      }
    }
  }

  &__btn {
    margin-top: 12px;
    position: relative;

    .tooltip-up-balance {
      &_position_bottom {
        top: 58px;
      }
    }
  }

  &__btn-mobile {
    display: none;
    margin-top: 24px;
    position: relative;
  }

  &__info {
    width: 300px;
    margin: 0;
  }

  .tariff-info {
    &::v-deep {
      h6 {
        @include text-2;
        color: $dark-primary;
        margin-bottom: 16px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      li {
        &:not(:last-child) {
          margin-bottom: $space-m;
        }

        h5 {
          @include text-2;
          color: $dark-primary;
          margin-bottom: 4px;
        }

        p {
          @include body-1-bold;
          color: $dark-primary;
        }
      }
    }
  }

  &__additional-info {
    padding: $space-m;
    background-color: $light-primary;
    box-shadow: 0px 0px 15px 0px rgba(197, 197, 197, 0.15);
    border-radius: 8px;
    width: 300px;
    margin: 0 0 0 $space-l;
  }

  .additional-info {
    &::v-deep {
      h5 {
        @include body-1-bold;
        color: $dark-primary;
        margin-bottom: 12px;
      }

      ul {
        padding-left: 16px;
        margin: 0;
      }

      li {
        @include text-2;
        color: $dark-primary;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.business_d {
  background-image: url("../../assets/images/tariff/business.png");
}

.premium_d {
  background-image: url("../../assets/images/tariff/premium.png");
}

.vip_d {
  background-image: url("../../assets/images/tariff/vip.png");
}

.vip_gold_d {
  background-image: url("../../assets/images/tariff/vip_gold.png");
}

.commission-popup {
  position: absolute;
  bottom: calc(50% + 4px);
  z-index: 2;
}

.installment {
  background: $dark-second;
  color: $light-primary;
  border-radius: 0 0 8px 8px;

  &-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: $space-xs $space-s;
    border-bottom: 1px solid $dark-fourth;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      width: 16px;
      height: 16px;
      mask: url("../../assets/icons/close_bold.svg") 0 0 / cover no-repeat;
      background: $light-sixth;
      cursor: pointer;
    }
  }

  &-info {
    padding: $space-l $space-xl;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: $space-m $space-xl;
  }

  &-description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: $space-m $space-xxl;
    flex-wrap: wrap;

    > p {
      @include caption-1();
      max-width: 260px;
    }

    > span {
      @include text-2;
      padding: 3px $space-s;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &-actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    > p {
      @include caption-1();
      max-width: 220px;
      > a {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}

.awaited {
  background-color: $gold-main;
  color: $dark-primary;
}
.warning {
  background-color: $red;
}
.success {
  background-color: $green;
}

@media (max-width: 575px) {
  .tariff {
    &__btn {
      display: none;
    }

    &__btn-mobile {
      display: block;
    }

    ::v-deep .tariff__yandex-split .ya-pay-button {
      margin-top: 8px !important;
    }
  }
} ;
</style>
