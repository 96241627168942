export const TARIFFS_DESCRIPTIONS = {
  business_d: {
    mainDescription:
      "<h6>Все преимущества тарифа Клиент Стандарт</h6> <ul> <li> <h5>Бонусы за покупки клиентов</h5> <p>6 поколений</p></li><li> <h5>За рекомендацию</h5> <p>1000 RC за Клиент Стандарт</p><p>800 RC за Клиент Смарт</p></li><li> <h5>Доступ к платформе обучения</h5> <p>План агентских вознаграждений:</p> <p>Базовый</p></li><li> <h5>Подключение ТСП</h5> <p>Нет</p></li></ul>",
    additionalDescription: null,
    previewMainDescription: [
      "Все преимущества пакета Клиент Стандарт",
      "Бонусы за покупки клиентов до 6 поколений",
      "1000 RC за продажу Клиент Стандарт",
      "800 RC за продажу Клиент Смарт",
      "Базовый доступ к платформе обучения и плану агентских вознаграждений",
    ],
    previewAdditionalDescription: null,
  },

  premium_d: {
    mainDescription:
      "<h6>Все преимущества тарифа Бизнес</h6> <ul> <li> <h5>Бонусы за покупки клиентов</h5> <p>12 поколений</p></li><li> <h5>За рекомендацию</h5> <p>1000 RC за Клиент Стандарт</p><p>800 RC за Клиент Смарт</p></li><li> <h5>Доступ к платформе обучения</h5> <p>План агентских вознаграждений:</p> <p>Расширенный</p></li><li> <h5>Подключение ТСП</h5> <p>Нет</p></li></ul>",
    additionalDescription: null,
    previewMainDescription: [
      "Все преимущества пакета Бизнес",
      "Бонусы за покупки клиентов до 12 поколений",
      "1000 RC за продажу Клиент Стандарт",
      "800 RC за продажу Клиент Смарт",
      "<p>Расширенный доступ к платформе обучения <br> и плану агентских вознаграждений</p>",
    ],
    previewAdditionalDescription: null,
  },

  vip_d: {
    mainDescription:
      "<h6>Все преимущества тарифа Премиум</h6> <ul> <li> <h5>Бонусы за покупки клиентов</h5> <p>16 поколений</p></li><li> <h5>За рекомендацию</h5> <p>1000 RC за Клиент Стандарт</p><p>800 RC за Клиент Смарт</p></li><li> <h5>Доступ к платформе обучения</h5> <p>План агентских вознаграждений:</p> <p>Полный</p></li><li> <h5>Подключение ТСП</h5> <p>Есть</p></li></ul>",
    additionalDescription:
      "<h5>Возможность подключать предприятия</h5> <ul> <li>Процент от сделки с предприятием</li><li> Лицензированное право на работу с юр. лицами от лица “RC&nbsp;Group” </li><li> 10% вознаграждение от общей комиссии в лично подключенных предприятиях </li><li> Вознаграждение с подключенных предприятий в вашей структуре </li></ul>",
    previewMainDescription: [
      "Все преимущества пакета Премиум",
      "Бонусы за покупки клиентов до 16 поколений",
      "1000 RC за продажу Клиент Стандарт",
      "800 RC за продажу Клиент Смарт",
      "Полный доступ к платформе обучения и плану агентских вознаграждений",
      "Подключение ТСП",
    ],
    previewAdditionalDescription: [
      "Процент от сделки с предприятием",
      "Лицензированное право на работу с юр. лицами от лица “RC&nbsp;Group”",
      "10% вознаграждение от общей комиссии в лично подключенных предприятиях",
      "Вознаграждение с подключенных предприятий в вашей структуре",
    ],
  },

  vip_gold_d: {
    mainDescription:
      "<h6>Все преимущества тарифа Франшиза в двойном размере</h6> <ul> <li> <h5>Бонусы за покупки клиентов</h5> <p>16 поколений</p></li><li> <h5>За рекомендацию</h5> <p>1000 RC за Клиент Стандарт</p><p>800 RC за Клиент Смарт</p></li><li> <h5>Доступ к платформе обучения</h5> <p>План агентских вознаграждений:</p> <p>Полный</p></li><li> <h5>Подключение ТСП</h5> <p>Есть</p></li></ul>",
    additionalDescription:
      "<h5>Возможность подключать предприятия</h5> <ul> <li>Процент от сделки с предприятием</li><li> Лицензированное право на работу с юр. лицами от лица “RC&nbsp;Group” </li><li> 10% вознаграждение от общей комиссии в лично подключенных предприятиях </li><li> Вознаграждение с подключенных предприятий в вашей структуре </li></ul>",
    previewMainDescription: [
      "Все преимущества пакета Франшиза в двойном размере",
      "Бонусы за покупки клиентов до 16 поколений",
      "1000 RC за продажу Клиент Стандарт",
      "800 RC за продажу Клиент Смарт",
      "Полный доступ к платформе обучения и плану агентских вознаграждений",
      "Подключение ТСП",
    ],
    previewAdditionalDescription: [
      "Процент от сделки с предприятием",
      "Лицензированное право на работу с юр. лицами от лица “RC&nbsp;Group”",
      "10% вознаграждение от общей комиссии в лично подключенных предприятиях",
      "Вознаграждение с подключенных предприятий в вашей структуре",
    ],
  },
};
