export default {
  data() {
    return {
      timer: null,
      daysLeft: 0,
      hoursLeft: 0,
      minutesLeft: 0,
      secondLeft: 0,
    };
  },

  computed: {
    timeLeft() {
      return (format = "DHMS") => {
        switch (format) {
          case "DHMS":
            return `${this.daysLeft} д ${this.hoursLeft} ч ${this.minutesLeft} м ${this.secondLeft} с`;
          case "HMS":
            return `${this.hoursLeft} ч ${this.minutesLeft} м ${this.secondLeft} с`;
          case "MS":
            return `${this.minutesLeft} м ${this.secondLeft} с`;
          case "S":
            return `${this.secondLeft} с`;
          case "D:H:M":
            return `<p>${this.daysLeft}<span>д</span>${this.hoursLeft}<span>ч</span>${this.minutesLeft}<span>м</span></p>`;
        }
      };
    },
  },

  methods: {
    startTimer: function (endDate) {
      this.timer = setInterval(() => {
        let now = new Date().getTime();
        let diff = new Date(endDate) - now;

        if (diff >= 0) {
          this.daysLeft = Math.floor(diff / (1000 * 60 * 60 * 24));
          this.hoursLeft = Math.floor(
            (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.minutesLeft = Math.floor(
            (diff % (1000 * 60 * 60)) / (1000 * 60)
          );
          this.secondLeft = Math.floor((diff % (1000 * 60)) / 1000);
        }
      }, 1000);
    },
  },
};
